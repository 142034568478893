.about {
  font-size: 13px;
  &__contents {
    .container {
      display: flex;
      padding: 0px;

      .container__child {
        width: 50%;
        flex-grow: 1;
      }
    }
    .career-title {
      width: 400px;
    }
    .career--wrap {
      display: flex;
      justify-content: start;
      align-items: stretch;

      .career {
        margin-right: 10px;
      }
    }
    a {
      color: $lightBlue;
    }
    .img-info {
      max-width: 940px;
      width: 100%;
    }
    .img-card {
      max-width: 340px;
    }
    .img-career {
      max-width: 400px;
      margin-right: 20px;
    }
    .img-webmoney {
      max-width: 50px;
    }
  }
}