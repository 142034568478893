.password-card {
  width: 100%;
  background: none;
  padding: 0;
  text-align: left;
  margin: 0;
  p {
    font-size: calc(2px + 3.6vmin);
    margin-bottom: 3vmax;
  }
  .error-message {
    margin: 2vmax 0;
  }
  &__heading {
    font-size: $font36;
    margin: 0 -2.6vw 1.5vmax;
    padding: 0 2.6vw 1vmax;
    border-bottom: 1px solid $gray;
  }
  &__subheading {
    background: $lightGray;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    margin: calc(3px + 2.8vmin) -2.6vw;
    padding: calc(1vw) 0 calc(1vw) 2.6vw;
    font-weight: normal;
    font-size: calc(2px + 3.8vmin);
  }
  &__input--normal {
    width: 100%;
    max-height: 72px;
    height: 10vmin;
    border-radius: 5px;
    font-size: calc(2px + 3.8vmin);
  }
  &__input--name {
    width: 48.5%;
    max-height: 72px;
    height: 10vmin;
    border-radius: 5px;
    font-size: calc(2px + 3.8vmin);
  }
  .button-wrapper {
    width: 100%;
    margin-top: 4.5vh;
  }
}