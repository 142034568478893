/* 購入確認(PC) */
.product {
  &__inner {
    display: flex;
  }
  &__thumbnail {
    display: inline-block;
    max-width: 400px;
    min-height: 400px;
    width: 42.5531915%;
    margin: 0 5% 0 0;
    img {
      width: 100%;
    }
  }
  &__container {
    max-width: 490px;
    width: calc(100% - 42.5531915%);
  }
  &__title {
    min-height: 30px;
    font-size: 20px;
  }
  &__price {
    font-size: 16px;
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
    &--big {
      font-size: 26px;
      margin: 0 10px;
      font-weight: bold;
    }
    &--small {
      font-size: 12px;
    }
  }
  &__description {
    min-height: 40px;
    font-size: 12px;
    margin: 0;
    padding:10px 0 20px;
    border-bottom: 1px solid $gray;
    white-space: pre-wrap;
  }
  &__amount {
    border-bottom: 1px solid $gray;
    padding: 10px 15px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  &__total {
    font-size: 20px;
    border-bottom: 1px solid $gray;
    padding: 15px 0 15px 20px;
    &--big-red {
      font-size: 36px;
      color: $red;
      padding: 0 10px 0 25px;
      font-weight: bold;
    }
    &--small {
      font-size: 12px;
    }
  }
  select {
    font-size: 14px;
  }
}

.delivery-notes {
  padding-top: 5px;
  font-size: 11px;
}

.delivery-address {
  font-size: 14px;
  margin-top: 35px;
  // .section-title {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   span {
  //     line-height: 180%;
  //   }
  // }
  p {
    text-align: center;
  }
  .form-inner {
    dl {
      dt {
        width: 20%;
        border-right: 1px solid $gray;
      }
    }
  }
  .agree-wrapper {
    text-align: center;
    a {
      color: $lightBlue;
    }
    &__checkbox {
      margin: 0 0 20px;
      padding: 10px 12px 10px 42px;
      box-shadow: 0 2px 3px 1px $gray;
      border: 1px solid $gray;
      border-radius: 4px;
      &:before {
        top: 21px;
      }
      &:after {
        top: 22px;
        left: 15px;
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .sold-out-premium {
    color: $red;
    font-weight: bold;
  }
  .button-wrapper {
    margin-top: 20px;
    display: flex;
    a,
    .button-wrap {
      display: inline-block;
    }
    &--centering {
      width: 100%;
      margin-left: -120px;
      text-align: center;
    }
  }
}


.modal-wrap {
  border-style: solid;
  border-width: 8px;
  border-color: rgba(255, 255, 255, 0.6);
  &.modal-wrap--green {
    border-color: rgba(17, 183, 159, 0.6);
  }
}

.purchase-modal {
  text-align: center;
  width: 600px;
  padding: 50px 0;
  background-color: $white;
  .modal__headline {
    font-size: 24px;
    margin-bottom: 20px;

    &.modal__headline--green {
      color: #11b79f;
    }
  }
  .modal__description {
    margin-bottom: 30px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
  // .button-red {
  //   margin: 50px 0;
  // }
}


.purchase-complete-webmoney {
  .message {
    margin: 40px 16px 0;
    text-align: left;
  }
  .button-wrapper {
    margin-top: 80px;
    text-align: center;
  }
}