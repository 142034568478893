.mypage {
  ul {
    margin: 0;
    padding-left: 20px;
    list-style: disc;
    min-height: 48px;
    li {
      a {
        width: 100%;
        display: inline-flex;
        align-items: center;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__panel {
    background: $lightGray;
    padding: 20px;
  }
  &__headline {
    font-size: 16px;
  }
  &__item {
    font-size: 14px;
    &--date {
      display: inline-block;
      width: 11.5%;
    }
    &--text {
      width: 88.5%;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.registration-info {
  font-size: 14px;
  margin-top: 30px;
  // .section-title {
  //   display: flex;
  //   justify-content: space-between;
  //   span {
  //     line-height: 180%;
  //   }
  // }
  p {
    text-align: center;
  }
  .form-inner-wrap {
    padding-top: 0;
  }
  .form-inner {
    dl {
      dt {
        width: 20%;
        border-right: 1px solid $gray;
      }
    }
  }
}

.history-item {
  width: 100%;
  border: 1px solid $gray;
  margin-bottom: 10px;
  a {
    display: flex;
    position: relative;
  }
  &__img {
    min-width: 160px;
    min-height: 160px;
    img {
      width: 160px;
      height: auto;
    }
  }
  &__contents {
    max-width: 780px;
    width: 100%;
    max-height: 160px;
    padding: 20px 10% 0 20px;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      right: 5%;
      top: 50%;
      width: 15px;
      height: 15px;
      border: 0px;
      border-top: solid 2px $black;
      border-right: solid 2px $black;
      -ms-transform: rotate(45deg) translateY(-50%);
      -webkit-transform: rotate(45deg) translateY(-50%);
      transform: rotate(45deg) translateY(-50%);
      margin-top: -4px;
    }
  }
  &__no {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin: 0 0 10px;

    p {
      font-size: 12px;
    }
  }
  &__title {
    font-size: 18px;
    line-height: 130%;
  }
  &__fix-wrapper {
    color: #666;
    position: absolute;
    bottom: 15px;
  }
  &__amount {
    // color: $colorfontGray;
    font-size: 14px;
    padding-right: 30px;
    font-weight: bold;
  }
  &__total {
    // color: $colorfontGray;
    font-size: 14px;
    font-weight: bold;
  }
  &__date {
    // color: $colorfontGray;
    font-size: 12px;
    margin: 0;
  }
  a:hover {
    text-decoration: none;
  }
}

.history-item-detail {
  border: 1px solid $gray;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
  .form-inner {
    margin-bottom: 0;
    border: none;
    border-top: 1px solid $gray;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    dl {
      width: 100%;
      line-height: 100%;
      dt {
        max-width: 125px;
        padding: 8px 0 8px 2%;
        width: 19%;
      }
      dd {
        padding: 5px 0 5px 2%;
        width: 81%;
      }
      &:nth-child(3), &:nth-child(4) {
        width: 50%;
        border-bottom: none;
        dt {
          width: 38%;
          padding-left: 4%;
        }
        dd {
          width: 62%;
          padding-left: 4%;
        }
      }
      &:nth-child(4) {
        border-left: 1px solid $gray;
      }
    }
  }
  &__img {
    display: inline-block;
    img {
      width: 200px;
      height: 200px;
    }
  }
  &__contents {
    width: calc(100% - 200px);
    &-wrapper {
      padding: 10px 2% 0 2%;
      font-size: 0;
    }
  }
  &__no {
    font-size: 12px;
    line-height: 100%;
    margin-bottom: 5px;
  }
  &__title {
    font-size: 18px;
    line-height: 130%;
    a:hover {
      text-decoration: none;
      color: $black;
      opacity: 0.9;
    }
  }
  &__amount {
    font-size: 14px;
    color: $colorfontGray;
    padding-right: 30px;
  }
  &__total {
    font-size: 14px;
    color: $colorfontGray;
    padding-right: 30px;
  }
  &__date {
    font-size: 12px;
    color: $colorfontGray;
  }
}

.prize-item-detail {
  ul {
    margin-bottom: 30px;
    li {
      border: 1px solid $gray;
      border-top: none;
      &:first-child {
        border-top: 1px solid $gray;
      }
    }
  }
}

.prize-card-detail {
  display: flex;
  p {
    margin: 0;
  }
  &__img {
    border-right: 1px solid $gray;
    img {
      width: 160px;
      height: 160px;
    }
  }
  &__contents {
    width: 100%;
    padding: 20px;
  }
  &__prize-name {
    font-size: 18px;
    margin-bottom: 0;
    line-height: 120%;
  }
  &__title {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 130%;
  }
  &__amount {
    font-size: 18px;
    line-height: 150%;
  }
  &__note {
    font-size: 12px;
    color: $colorfontGray;
    line-height: 120%;
    white-space: pre-wrap;
  }
  &__button-wrapper {
    padding: 0 20px;
    align-self: center;
  }
  .SocialMediaShareButton--twitter {
    width: 84px;
  }
}

.history-item-loading {
  position: relative;
  height: 100px;
  img {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto;
    align-items: center;
    width: 80px;
    height: 80px;
  }
}