/* 登録情報編集(SP) */
.user-edit {
  .section-title {
    @include section-title-center;
  }
}

.card-edit, .unsubscribe {
  margin-top: 6vmax;
  .section-title {
    text-align: center;
    span {
      padding-left: 0;
    }
  }
  .panel {
    background: none;
    text-align: left;
    padding: 0;
    p {
      font-size: $font28;
      line-height: 130%;
      margin-bottom: 3vmax;
    }
  }
}

.unsubscribe {
  margin-bottom: 0;
}

.user-edit-confirm {
  p {
    font-size: $font28;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    a {
      max-width: 140px;
      width: calc(5px + 20vmin);
      margin-top: 4vmax;
    }
    &--centering {
      margin-left: 0;
    }
  }
}

.edit-back-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  a:first-child {
    max-width: 140px;
    width: calc(5px + 20vmin);
    margin-top: 4vmax;
  }
  .button-back {
    margin-right: 0;
  }
}