.prize-card, .prize-card-once{
  width: 32%;
  max-width: 32%;
  border: 1px solid $gray;
  margin: 0 2% 20px 0;
  position: relative;
  &__img {
    position: relative;
    img {
      max-width: 100%;
      height: auto;
      box-sizing: border-box;
    }
    &--badge {
      display: inline-block;
      position: absolute;
      left: 10px;
      top: -2px;
      img {
        max-width: 80px;
        height: auto;
      }
    }
  }
  &__contents {
    border-top: 1px solid $gray;
    padding: 10px;
    &--prize-name {
      font-size: 18px;
      margin: 0;
      line-height: 130%;
    }
    &--title {
      font-size: 18px;
      margin: 0 0 calc(30px + 30px);
      line-height: 100%;
    }
  }
  &__button-wrapper{
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    bottom: 10px;
  }
  &__amount {
    font-size: 18px;
    font-weight: bold;
    line-height: 100%;
    &--red {
      color: $red;
      font-size: 30px;
    }
  }
}

// 特賞用
.prize-card-big {
  width: 49%;
  max-width: 49%;
  border: 1px solid $gray;
  margin: 0 2% 20px 0;
  position: relative;
  &__img {
    position: relative;
    img {
      max-width: 100%;
      height: auto;
      box-sizing: border-box;
    }
    &--badge {
      display: inline-block;
      position: absolute;
      left: 10px;
      top: 10px;
      img {
        max-width: 105px;
        height: auto;
      }
    }
  }
  &__contents {
    width: 100%;
    border-top: 1px solid $gray;
    padding: 10px;
    &--prize-name {
      font-size: 18px;
      margin: 0;
      line-height: 130%;
    }
    &--title {
      font-size: 18px;
      margin: 0 0 calc(30px + 30px);
      line-height: 100%;
    }
  }
  &__button-wrapper{
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    bottom: 10px;
  }
  &__amount {
    font-size: 18px;
    font-weight: bold;
    line-height: 100%;
    &--red {
      color: $red;
      font-size: 30px;
    }
  }
}

.result {
  .button-wrapper {
    text-align: center;
    margin-top: 10px;
    a {
      @include button-centering;
      width: 340px;
      &:first-child {
        width: 450px;
        margin-bottom: 25px;
      }
      &:last-child {
        margin: 40px auto;
      }
    }
  }
}

.result-list {
  &__wrapper--special {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    li:last-child {
      margin: 0 0 20px 0;
    }
    li:nth-child(2n) {
      margin: 0 0 20px 0;
    }
  }
  &__wrapper--other, &__wrapper--other-once {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    li:last-child {
      margin: 0 0 20px 0;
    }
    li:nth-child(3n) {
      margin: 0 0 20px 0;
    }
  }
}

.order-info {
  margin-top: 25px;
  .form-inner {
    dl {
      dt {
        width: 20%;
        border-right: 1px solid $gray;
      }
      dd {
        width: 80%;
      }
    }
  }
  .button-wrapper {
    text-align: center;
    a {
      @include button-centering;
      width: 340px;
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}