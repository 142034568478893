.top-container {
  width: 100%;
  padding: 0 2.6vw;
}

.item-card {
  width: 48.5%;
  min-height: auto;
  &__img {
    min-height: 23.5vw;
    // img {
    //   width: 100%;
    //   height: 100%;
    // }
    // &--price {
    //   top: 90%;
    //   right: 3%;
    //   max-width: 100px;
    //   width: calc(8px + 12vmin);
    //   max-height: 100px;
    //   height: calc(8px + 12vmin);
    //   font-size: calc(4px + 3vmin);
    //   padding: calc(2.0vmin) 0;
    //   -webkit-transform: translateX(-2%) translateY(-92%);
    //   -ms-transform: translateX(-2%) translateY(-92%);
    //   transform: translateX(-2%) translateY(-92%);
    //   span {
    //     font-size: calc(2px + 2.5vmin);
    //   }
    // }
  }
  &__info {
    height: 4vw;
  }
  &__contents {
    padding: 1.5vmin 1.5vmin 16vw;
    &--title {
      margin: 1vmax 0 0;
      font-size: $font24;
      padding-left: 5px;
    }
  }
  &__price {
    font-size: 2.6vw;
  }
  &__sales-period {
    display: block;
    padding: 0 0 0 1.5vmin;
    &--text {
      padding: 0;
      line-height: 200%;
      font-size: calc(3.3vmin);
      white-space: nowrap;
    }
  }
}

.item-sort {
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  margin-top: 3vmax;
  margin-bottom: 3vmax;
  padding: 0;
  text-align: center;
  li {
    flex-basis: auto;
    font-size: calc(3.5vmin);
    margin: 0 calc(4vmin);
    line-height: 250%;

    .category-link {
      padding: 0 1px;

      &::before{
        bottom: -4px;
      }
    }
  }
}
.sale-item-list {
  min-height: 76vw;

  .item-wrapper {
    margin-bottom: 0;
  }
  .item-card {
    // margin-top: calc(2.5vmax);
    margin: 0 0 9vw;
  }
}

.no-sale-item-wrap {
  height: 38vw;
}
.no-sale-item-text {
  padding: 6vw 4vw;
  border: 2px solid #e3e3e3;
  font-size: calc(2px + 3vw);
}

.sold-item-list {
  min-height: 76vw;

  .item-wrapper {
    margin-bottom: 0;
  }
  .section-title {
    text-align: center;
    font-size: calc(0.4rem + 4vmin);
    span {
      display: block;
      width: 100%;
      text-align: center;
      padding-left: 0;
    }
  }
  .item-card {
    // margin-top: calc(2.5vmax);
    margin: 0 0 9vw;
  }
  .button-wrapper {
    margin: 0 0 12.5vw;
  }
}

.category-list{
  .button-wrapper {
    margin: 0 0 18.2vw;
  }
}

.info {
  margin-top: 0;
  margin-bottom: 8.1vw;
  width: 100%;
  .section-title {
    font-size: calc(2px + 4.2vmin);
    padding-bottom: 1vmax;
    margin: 0 -2.6vw 1.5vmax;
    span {
      margin: 0 auto;
      padding: 0;
      font-size: calc(0.4rem + 4vmin);
    }
  }
  .sp {
    margin-top: 4vmax;
  }
}

.info__item {
  width: 100%;
  margin: 0;
  padding: 0;
  span {
    font-size: calc(1px + 3.2vmin);
  }
  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2.6vw;
  }
  &--date {
    width: 100%;
    line-height: 200%;
  }
  &--text {
    width: 100%;
  }
}

.official-sns {
  margin-top: 7vw;
  span {
    font-size: calc(0.4rem + 3vmin);
  }
  &__icon-wrapper {
    img {
      max-width: 68px;
      width: calc(10px + 8vmin);
      height: auto;
    }
  }
}

// slider
.top-slider {
  position: relative;
  margin: 14vmin auto 0;
  max-width: 100%;
  min-height: 52vw;
  .single-top-image {
    width: 100% !important;
  }
}
.swiper-slide {
  width: 100% !important;
  img {
    width: 100%;
    border: none;
  }
}

.swiper-slide-prev, .swiper-slide-next {
  opacity: 1;
}

.swiper-button-prev, .swiper-button-next {
  background-color: $white;
  opacity: 0.9;
  width: 40px;
  height: 40px;
  color: $black;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  position: static;
  margin-top: 2vmin;
  .swiper-pagination-bullet {
    margin: 0 1.5vmin;
    width: calc(1px + 1.7vmin);
    height: calc(1px + 1.7vmin);
  }
}

.caution-item {
  position: relative;
  margin: 5.4vw auto 0;
  padding: 12px;
  width: 95%;
  border: 1px solid $red;
  text-align: center;
  font-size: $font24;
  a {
    color: $red;
  }
}

.information-wrap {
  display: block;
  margin-top: 3vmax;
}

.timeLine-wrap {
  margin: 0 auto;
}

.official-twiiter {
  width: 100%;
  // width: 48%;

  .section-title {
    padding-bottom: 1vmax;
    span {
      margin: 0 auto;
      padding: 0;
      font-size: calc(0.4rem + 4vmin);
    }
  }
}