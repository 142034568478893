.recaptcha {
  margin: 0 auto;
  width: 300px;
}

.confirm-form {
  .error-box {
    margin: 0 auto 20px;
    padding: 10px 20px;
    border: 1px solid #EA352D;
    text-align: center;
  }
}