.notes{
  &__contents {
    font-size: 3vw;

    a {
      color: $lightBlue;
    }
    .caution {
      color: $red;
    }
  }
}