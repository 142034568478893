.about {
  &__contents {
    width: 100%;
    .container {
      display: table-row;

      .container__child {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    a {
      color: $lightBlue;
    }
    .img-info {
      max-width: 720px;
      width: 100%;
    }
    .career-title {
      width: auto;
      margin-bottom: 0;
    }
    .career--wrap {
      width: 100%;

      .career {
        margin-right: 10px;
      }
    }
    .img-card {
      max-width: 400px;
      width: 80%;
    }
    .img-career {
      max-width: 350px;
      width: 100%;
      height: 100%;
    }
    .img-webmoney {
      max-width: 60px;
      width: 100%;
      height: 100%;
    }
  }
}