.policy {
  font-size: 13px;

  p {
    margin: 0;
  }
  h4 {
    margin-bottom: 0;
  }
  &__table {
    margin: 15px auto 20px auto;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  &__table th {
    width: 20%;
    min-width: 100px;
    padding: 8px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #333;
    background-color: #f0f0f0;
    font-weight: normal;
    // font-size: 14px;
  }
  &__table td {
    width: 80%;
    padding: 8px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    // font-size: 13px;
    a {
      color: $lightBlue;
    }
  }
}