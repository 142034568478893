.product {
  display: flex;
  flex-direction: column;
  &__inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__thumbnail {
    max-width: calc(100% + 5.2vw);
    width: calc(100% + 5.2vw);
    // min-height: calc(100% + 5.2vw);
    min-height:320px;
    margin: 0 -2.6vw;
    img{
      max-width: 100%;
    }
  }
  &__container {
    max-width: 100%;
    width: 100%;
  }
  &__title {
    font-size: $font30;
    margin: 2vmax 0;
  }
  &__price {
    font-size: $font28;
    width: calc(100% + calc(2.6vw + 2.6vw));
    margin: 0 -2.6vw 1.5vmax;
    padding: 0 2.6vw 1vmax;
    &--big {
      font-size: $font40;
      margin: 0 2vw 0 4vw;
    }
    &--small {
      font-size: $font28;
    }
  }
  &__description {
    font-size: $font24;
    width: calc(100% + calc(2.6vw + 2.6vw));
    margin: 0 -2.6vw;
    padding: 0 2.6vw 1.5vmax;
  }
  &__amount {
    width: calc(100% + calc(2.6vw + 2.6vw));
    margin: 0 -2.6vw;
    padding: 2vmax 2.6vw;
    &--wrapper {
      width: 50%;
    }
    span {
      font-size: $font26;
      &:first-child {
        margin-right: 2vmin;
        font-size: $font28;
      }
    }
    .select-number-wrapper {
      display: inline-block;
      width: 42%;
    }
    .select-number-bg {
      &__select--number {
        width: 100%;
        font-size: $font28;
        text-indent: calc(1px + 0.25vw);
      }
      &::after {
        position: absolute;
        top: 30%;
        right: 24%;
        width: 0;
        height: 0;
        padding: 0;
        pointer-events: none;
      }
    }
  }
  &__total {
    font-size: $font28;
    text-align: center;
    width: calc(100% + calc(2.6vw + 2.6vw));
    margin: 0 -2.6vw;
    padding: 2.5vmax 2.6vw 1.5vmax;
    &--big-red {
      font-size: $font56;
      padding: 0 1.8vmax;
    }
    &--small {
      font-size: $font28;
    }
  }
}

.delivery-notes {
  margin-top: 1vw;
  margin-bottom: 0;
  line-height: 150%;
  font-size: 3vw;
}

.delivery-address {
  font-size: $font28;
  margin-top: 3vmax;
  .section-title {
    padding-right: 2.6vw;
    span {
      // line-height: 210%;
      line-height: 100%;
    }
    .button-mini-round {
      // max-width: 72px;
      width: 24%;
    }
  }
  .form-inner {
    padding: 0;
    border: 1px solid $gray;
    border-bottom: none;
    font-size: calc(1px + 3.5vmin);
    dl {
      width: 100%;
      border-bottom: 1px solid $gray;
      margin: 0;
      display: table;
      dt {
        width: 33%;
        min-width: 35%;
        border: 0;
        padding: 0.5vmax 1.3vw;
        display: table-cell;
        vertical-align: middle;
      }
      dd {
        width: 67%;
        border: 0;
        padding: 0.5vmax 1.3vw;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .agree-wrapper {
    &__checkbox {
      width: 100%;
      margin: 0 0 3vmax;
      text-align: center;
      // padding: 2vmax 0;
      padding: 12px 0;
      box-shadow: 0 calc(0.5vmin) calc(1vmin) calc(0.5vmin) $gray;
      border-radius: 4px;
      &:before {
        // top: calc(2px + 44%);
        top: 24px;
        left: 8.5%;
      }
      &:after {
        // top: calc(5px + 43%);
        top: 25px;
        left: 6%;
      }
      span {
        font-size: 4vw;
        padding-left: 3.5vw;
      }
    }
    &__text {
      font-size: $font28;
    }
  }
  .sold-out-premium {
    font-size: $font28;
    // margin: 3vmax 0 0;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5vw;
    .button-wrap {
      width: calc(5px + 20vmin);
      margin-top: 4vmax;
    }
    &--centering {
      margin-left: 0;
      a {
        width: 100%;
      }
    }
  }
}

.purchase-modal {
  width: 100%;
  padding: 4vh 2.6vw;
  .modal__headline {
    font-size: calc(5px + 4vmin);
    margin-bottom: 20px;
  }
  p {
    font-size: $font28;
    margin: 0;
  }
  .modal__description {
    margin-bottom: 3vh;
  }

  .modal__text {
    display: inline-block;
    font-size: 3.6vw;
    white-space: pre-line;
    // text-align: left;
  }
  .button-red {
    width: 100%;
    margin: 3vh 0 0;
  }
}


.purchase-complete-webmoney {
  .message {
    margin: 40px 0 0;
  }
  .button-wrapper {
    margin-top: 40px;
  }
}