.top-container {
  max-width: 1048px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  flex-grow: 1;
}

.item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &::after {
    content:"";
    display: block;
    width: 32.5%;
  }
}

.item-sort {
  max-width: 750px;
  margin: 30px auto;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-around;
  li {
    display: inline-block;
    text-align: center;
    font-size: 14px;
    flex-grow: 1;
    flex-basis: auto;

    .category-link {
      text-decoration: none;
      position: relative;
      color: $lightBlue;
      padding: 0 5px;
      cursor: pointer;
      &::before{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        justify-content: center;
        height: 2px;
        background-color: $red;
        transition: width 300ms;
        width: 0%;
      }

      &:hover {
        text-decoration: none;
        color: $red;
      }
    }
  }
  .category-link.active {
    color: $red;
    text-decoration: none;
    &::before{
      width: 100%;
    }
  }
}
.sale-item-list {
  min-height: 370px;
  .item-card {
    margin-bottom: 30px;
  }
}

.no-sale-item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 250px;
}

.no-sale-item-text {
  padding: 40px;
  border: 4px solid #e3e3e3;
  font-size: 18px;
  font-weight: bold;
}

.sold-item-list {
  min-height: 370px;
  .item-card {
    margin-bottom: 30px;
  }
}

.category-list {
  .button-wrapper {
    text-align: center;
    margin: 0 0 70px;
  }
}

// ソートアニメーション検証
@mixin animationValue( $name, $duration, $function, $delay, $count, $state){
  -webkit-animation: $name $duration+s $function $delay+s $count $state;
  animation: $name $duration+s $function $delay+s $count $state;
}

// $sortEnd: 6;
// @for $i from 1 through $sortEnd {
//   .item-card:nth-child(#{$i}){
//     @include animationValue(fadein, 1, linear, $i * 0.2 , 1, forwards);
//   }
// }

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.item-card {
  width: 32%;
  min-height: 320px;
  border-bottom: 1px solid $lightgray1;
  opacity: 0;
  position: relative;
  a {
    display: inline-block;
    width: 100%;
  }
  a:hover {
    text-decoration: none;
  }
  &__img {
    position: relative;
    min-height: 127px;
    height: 100%;
    padding-bottom: 56.25%;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border: solid 1px #e3e3e3;
    }
    // &--price {
    //   position: absolute;
    //   top: 92%;
    //   right: 2%;
    //   width: 80px;
    //   height: 80px;
    //   border-radius: 50%;
    //   text-align: center;
    //   padding-top: 15px;
    //   color: $white;
    //   background-color: $labelBgColorPrice;
    //   // z-index: 1;
    //   font-size: 20px;
    //   font-weight: bold;
    //   line-height: 120%;
    //   -webkit-transform: translateX(-2%) translateY(-92%);
    //   -ms-transform: translateX(-2%) translateY(-92%);
    //   transform: translateX(-2%) translateY(-92%);
    //   span {
    //     color: $white;
    //     font-size: 16px;
    //   }
    // }
  }

  &__icon {
    &--new {
      background-image: url("../images/common/icon_new.png");
      background-repeat: no-repeat;
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 12%;
      height: 30%;
      background-size: 100%;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 18px;
  }
  &__price {
    // margin-bottom: 1rem;
    display: inline-block;
    //width: 210px;
    margin: 0;
    padding: 0;
    text-align: right;
    font-size: 13px;
    // line-height: 1em;
    // vertical-align: middle;
  }
  &__contents {
    padding: 10px 10px 60px;
    font-size: 0;
    &--title {
      font-size: 14px;
      line-height: 150%;
      margin: 10px 0 0 0;
      padding-left: 10px;
      border-left: 5px solid #ef6f6f;
      letter-spacing: 0.04em;
    }
    &--sale { border-left-color: #ef6f6f; }
    &--coming-soon { border-left-color: #11b79f; }
    &--closing-soon { border-left-color: #f9802a; }
    &--sold-out { border-left-color: #ccc; }
  }
  &__sales-period {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    padding: 0 0 5px;
    &--text {
      font-weight: bold;
      // padding-left: 15px;
      white-space: nowrap;
    }
  }
}

.info {
  // width: 48%;
  width: 58%;
  &__link {
    font-size: 14px;
    position: relative;
    padding-right: 15px;
    a:hover {
      text-decoration: none;
      color: $black;
    }
    &--text {
      &::after {
        position: absolute;
        content: '';
        right: 3%;
        top: 60%;
        width: 10px;
        height: 10px;
        border: 0px;
        border-top: solid 2px $black;
        border-right: solid 2px $black;
        -ms-transform: rotate(45deg) translateY(-50%);
        -webkit-transform: rotate(45deg) translateY(-50%);
        transform: rotate(45deg) translateY(-50%);
        margin-top: -4px;
      }
    }
    img {
      margin-left: 10px;
    }
  }
  // .section-title {
  //   margin-bottom: 0;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }
  .item-wrapper {
    margin-bottom: 0;
  }
}

.info__item {
  display: flex;
  padding: 20px 15px;
  // border-bottom: 1px solid $gray;
  width: 100%;
  background-image: -webkit-linear-gradient(left, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
  background-image: -moz- oldlinear-gradient(left, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
  background-image: -o-linear-gradient(left, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
  background-image: linear-gradient(to right, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
  background-repeat: repeat-x;
  background-position: left bottom;
  -webkit-background-size: 4px 1px;
  -moz-background-size: 4px 1px;
  -o-background-size: 4px 1px;
  background-size: 4px 1px;
  span {
    font-size: 14px;
    color: inherit;
  }
  a {
    width: 100%;
    &:hover {
      // text-decoration: none;
      color: #eb342e;
    }
  }
  // a:hover {
  //   text-decoration: none;
  //   color: #eb342e;
  // }
  &--date {
    display: inline-block;
    max-width: 100px;
    width: 12%;
    // padding-right: 15px;
  }
  &--text {
    display: inline-block;
    width: 100%;
  }
}

.official-sns {
  // text-align: center;
  margin: 60px auto 0;

  display: flex;
  // margin: 0 auto;
  flex-direction: row;
  justify-content: center;
  span {
    font-size: 20px;
    font-weight: bold;
  }


  &__icon-wrapper {
    display: inline-flex;
    justify-content: space-between;
    img {
      width: 40px;
      height: auto;
    }
  }
  .icon {
    margin: 0 10px;
  }
}

.top-slider {
  position: relative;
  margin: -35px auto 0;
  // width: 800px;
  max-width: 100%;
  min-height: 455px;
  .single-top-image {
    width: 100% !important;
  }
}
.swiper-slide {
  max-width: 800px;
  width: 100% !important;
  img{ border: solid 1px #e3e3e3; }
}

.swiper-slide-prev, .swiper-slide-next {
  opacity: 0.6;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  background-color: $white;
  opacity: 0.8;
  width: 40px;
  height: 40px;
  color: $black;
  background-size: 15px 30px;
  top: calc(50% - 20px);
  filter: grayscale(100%);
  border-radius: 50%;
  // border: 1px solid  #dedcdc;
  box-shadow: 0px 0px 8px #000;

  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
}
.swiper-button-prev {
  left: auto;
  right: calc(50% + 385px);
}
.swiper-button-next {
  left: calc(50% + 385px);
  right: auto;
}

.swiper-pagination {
  position: static;
  margin-top: 15px;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  position: static;
  margin-top: 10px;
  .swiper-pagination-bullet {
    margin: 0 7px;
    &:focus {
      outline: none;
    }
  }
}

.swiper-pagination-bullet-active {
  background: $black;
}

.caution-item {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  width: 800px;
  border: 1px;
  border-style: solid;
  border-color: $red;
  text-align: center;
  a {
    color: $red;
  }
}

.SandboxRoot.env-bp-430 .timeline-Header-title, .SandboxRoot.env-bp-550 .timeline-Header-title {
  font-size: 13px;
}

.information-wrap {
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.official-twiiter {
  width: 40%;
}
.timeLine-wrap {
  min-height: 500px;
  border: 1px solid #ccc;
}