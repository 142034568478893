.mypage {
  &__panel {
    padding: 0;
    background: none;
    ul {
      list-style-type: none;
      min-height: 26vw;
      padding: 0;
      li {
        // border-bottom: 1px solid $gray;
        font-size: $font24;
        display: flex;
        width: 100%;
        background-image: -webkit-linear-gradient(left, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
        background-image: -moz- oldlinear-gradient(left, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
        background-image: linear-gradient(to right, #b2b2b2, #b2b2b2 1px, transparent 1px, transparent 3px);
        background-repeat: repeat-x;
        background-position: left bottom;
        background-size: 4px 1px;
        // &:first-child {
        //   border-top: 1px solid $gray;
        // }
        a {
          padding: 1.3vmax 2vw;
        }
      }
    }
  }
  &__item {
    &--date {
      display: inline-block;
      width: 25%;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 380%;
        top: 60%;
        width: calc(3px + 1.5vmin);
        height: calc(3px + 1.5vmin);
        border: 0px;
        border-top: solid 2px $black;
        border-right: solid 2px $black;
        -ms-transform: rotate(45deg) translateY(-60%);
        -webkit-transform: rotate(45deg) translateY(-60%);
        transform: rotate(45deg) translateY(-60%);
        margin-top: -4px;
        overflow: visible;
      }
    }
    &--text {
      width: 70%;
      position: relative;
    }
  }
}

.registration-info {
  font-size: $font28;
  margin-top: 3vmax;
  .section-title {
    padding-right: 2.6vw;
    margin-bottom: 1.5vmax;
    span {
      // line-height: 210%;
      line-height: 100%;
    }
    .button-mini-round {
      // max-width: 160px;
      // width: 100%;
      width: 24%;
    }
  }
  .form-inner-wrap {
    padding-top: 4.1vw;
  }
  .form-inner {
    padding: 0;
    border: 1px solid $gray;
    border-bottom: none;
    font-size: calc(1px + 3.5vmin);
    dl {
      width: 100%;
      border-bottom: 1px solid $gray;
      margin: 0;
      display: table;
      dt {
        width: 33%;
        min-width: 35%;
        border: 0;
        padding: 0.5vmax 1.3vw;
        display: table-cell;
        vertical-align: middle;
      }
      dd {
        width: 67%;
        border: 0;
        padding: 0.5vmax 1.3vw;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}

.history-item {
  // margin: 0 -2.6vw;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid $gray;
  // width: calc(100% + calc(2.6vw * 2));

  // &:first-child {
  //   border-top: 1px solid $gray;
  // }
  a {
    padding: 4.1vw 0;
  }
  &__img {
    min-width: 0;
    max-width: 180px;
    min-height: 0;
    width: 24vmin;
    height: auto;
    img {
      max-width: 180px;
      width: calc(24vmin);
      height: auto;
    }
  }
  &__contents {
    width: 68%;
    max-height: 100%;
    padding: 0 0 0 2vw;
    &::after {
      // position: static;
      right: -5%;
      top: 50%;
      width: calc(3px + 1.5vmin);
      height: calc(3px + 1.5vmin);
      border: 0px;
      border-top: solid 2px $black;
      border-right: solid 2px $black;
      -ms-transform: rotate(45deg) translateY(-50%);
      -webkit-transform: rotate(45deg) translateY(-50%);
      transform: rotate(45deg) translateY(-50%);
      margin-top: -4px;
    }
  }
  &__no {
    font-size: $font24;
    margin: 0 0 0.5vmax;
    p{
      font-size: calc(1px + 3vmin);
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: $font28;
    // margin-bottom: 0.3vmax;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__fix-wrapper {
    bottom: 0;
  //   position: static;
  }
  &__amount {
    font-size: $font24;
    font-weight: normal;
    padding-right: 3vmin;
    line-height: 180%;
  }
  &__total {
    font-size: $font24;
    font-weight: normal;
  }
  &__date {
    font-size: calc(1px + 3vw);
  }
}

.history-item-detail {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 -2.6vw;
  padding: 2.6vw 0 0 2.6vw;
  border: none;
  border-top: 1px solid $gray;
  .sp {
    margin: 0 -2.6vw;
    hr {
      height: 1px;
      background-color: $gray;
      border: none;
      color: $gray;
      margin: 0;
    }
  }
  .form-inner {
    display: flex;
    position: static;
    padding: 2.6vw;
    border: none;
    dl {
      width: calc(100% - 2.6vw);
      line-height: 100%;
      margin: 0;
      border: 1px solid $gray;
      border-bottom: none;
      display: table;
      dt {
        padding: 0 2vmin;
        width: 31%;
        border: none;
        display: table-cell;
        vertical-align: middle;
      }
      dd {
        padding: 0.8vmax 2vmin;
        width: 69%;
        display: table-cell;
        vertical-align: middle;
        line-height: 130%;
      }
      &:nth-child(3), &:nth-child(4) {
        width: calc(100% - 2.6vw);
        dt {
          width: 31%;
          padding: 0 2vmin;
        }
        dd {
          width: 69%;
          padding-left: 2vmin;
        }
      }
      &:last-child {
        border-bottom: 1px solid $gray;
      }
    }
  }
  &__img {
    max-width: 180px;
    width: 24vmin;
    max-height: 180px;
    height: auto;
    img {
      max-width: 180px;
      width: calc(24vmin);
      height: auto;
    }
  }
  &__contents {
    width: 73%;
    max-height: 100%;
    padding: 0 0 0 2vw;
    font-size:0;
    padding-bottom: 2.6vw;
    &-wrapper {
      padding: 0;
    }
  }
  &__no {
    font-size: $font24;
    line-height: 100%;
    margin-bottom: 0;
  }
  &__title {
    font-size: $font28;
    line-height: 120%;
    padding-top: 1vmax;
    margin-bottom: 1.5vmax;
  }
  &__amount {
    font-size: $font24;
    padding-right: 3vmin;
    line-height: 180%;
  }
  &__total {
    font-size: $font24;
    padding-right: 3vmin;
    line-height: 180%;
  }
  &__date {
    font-size: $font24;
    line-height: 100%;
  }
}


.prize-item-detail {
  ul {
    margin-bottom: 30px;
    li {
      border: 1px solid $gray;
    }
  }
}

.prize-card-detail {
  display: block;
  max-width: 100%;
  margin: 0 0 2vmax;
  border: 1px solid $gray;
  p {
    padding-top: 1.5vmax;
  }
  &__img {
    width: 100%;
    border-right: 0;
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
  &__contents {
    border-top: 1px solid $gray;
    padding: 2vmin;
  }
  &__prize-name {
    font-size: $font26;
    margin: 0;
  }
  &__title {
    font-size: $font26;
    margin: 0 0 3vmax;
    line-height: 120%;
  }
  &__button-wrapper.sp{
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width : 100%;
    padding: 0;
  }
  &__amount {
    font-size: $font24;
    &--bold {
      font-size: $font36;
      font-weight: bold;
    }
  }
  &__note {
    font-size: $font24;
  }
  .SocialMediaShareButton--twitter {
    max-width: 180px;
    width: calc(3px + 23.5vmin);
    max-height: 40px;
    height: calc(1px + 3vmax);
  }
}