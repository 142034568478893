.faq {
  p {
    margin-bottom: 1vmax;
    line-height: 130%;
  }
  .Q, .A {
    font-size: $font30;
    margin-right: 2vmin;
  }
  &__toc {
    padding-bottom: 1vmax;
    li {
      font-size: $font30;
      border-left: none;
      border-right: none;
      margin: 0 -2.6vw;
      padding: calc(1.5vw) 0 calc(1.5vw) 2.6vw;
      .Q {
        font-size: $font40;
      }
    }
  }
  &__contents {
    margin: 4vmax 0 0;
  }
  &__contents-inner {
    padding: 1.5vmax 0 2.5vmax;
    margin-bottom: 0;
    div:last-child {
      margin-bottom: 0;
    }
  }
  &__headline {
    font-size: $font30;
    margin: 0 -2.6vw;
    padding: calc(1.5vmax) 0 calc(1.5vmax) 2.6vw;
  }
  &__item-list {
    margin: 0 0 2vmax;
    &--question {
      font-size: $font28;
      white-space: pre-wrap;
    }
    &--answer {
      font-size: $font26;
      white-space: pre-wrap;
    }
  }
}

// ページ内リンク位置ズレ調整用
#about, #beginner, #product {
  margin-top: calc(#{$headerHight} * -1);
  padding-top: $headerHight;
}