.prize-card {
  max-width: 100%;
  width: calc(calc(100% - 2.6vw) / 2);
  margin: 0 0 2vmax;
  &__img {
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
    &--badge {
      position: absolute;
      left: 5%;
      top: -2px;
      img {
        max-width: 90px;
        width: 12vmin;
        height: auto;
      }
    }
  }
  &__contents {
    border-top: 1px solid $gray;
    padding: 1vmin;
    &--prize-name {
      font-size: $font24;
      margin: 0;
    }
    &--title {
      font-size: $font24;
      margin: 0 0 7vmax;
      line-height: 120%;
      text-overflow: ellipsis;
      min-width: 0;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
  &__button-wrapper{
    padding: 0 1vmin;
    bottom: 1vmin;
  }
  &__amount {
    font-size: calc(3px + 2.6vmin);
    line-height: 100%;
    &--red {
      color: $red;
      font-size: calc(0.25rem + 4vmin);
    }
  }
}

// 特賞用
.prize-card-big {
  max-width: 100%;
  margin: 0 0 2vmax;
  &__img {
    img {
      max-width: 100%;
      height: auto;
    }
    &--badge {
      position: absolute;
      left: 2%;
      top: 2%;
      img {
        max-width: 170px;
        width: calc(15px + 20vmin);
        height: auto;
      }
    }
  }
  &__contents {
    border-top: 1px solid $gray;
    padding: 2vmin;
    &--prize-name {
      font-size: $font26;
      margin: 0;
    }
    &--title {
      font-size: $font26;
      margin: 0 0 7.5vmax;
      line-height: 120%;
      text-overflow: ellipsis;
      min-width: 0;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
  &__button-wrapper{
    padding: 0 2vmin;
    bottom: 2vmin;
  }
  &__amount {
    font-size: $font24;
    &--red {
      font-size: $font36;
    }
  }
}

// 1回のみ用
.prize-card-once {
  max-width: 100%;
  width: 100%;
  margin: 0 0 2vmax;
  &__img {
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    &--badge {
      position: absolute;
      left: 2%;
      top: -2px;
      img {
        max-width: 170px;
        width: calc(15px + 20vmin);
        height: auto;
      }
    }
  }
  &__contents {
    border-top: 1px solid $gray;
    padding: 2vmin;
    &--prize-name {
      font-size: $font26;
      margin: 0;
    }
    &--title {
      font-size: $font26;
      margin: 0 0 7.5vmax;
      line-height: 120%;
      text-overflow: ellipsis;
      min-width: 0;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
  &__button-wrapper{
    padding: 0 2vmin;
    bottom: 2vmin;
  }
  &__amount {
    font-size: $font24;
    &--red {
      font-size: $font36;
    }
  }
}



.result {
  p {
    font-size: $font28;
  }
  .button-wrapper {
    text-align: center;
    a {
      @include button-centering;
      width: 100%;
      &:first-child {
        width: 100%;
        margin-bottom: 3vmax;
      }
      &:last-child {
        margin: 5vmax auto;
      }
    }
  }
}

.result-list {
  &__wrapper--special {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    li:last-child {
      margin: 0 0 2vmax;
    }
  }
  &__wrapper--other {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 2vmax;
    li:last-child {
      margin: 0 0 2vmax;
    }
    li:nth-child(3n) {
      margin: 0 0 2vmax;
    }
  }
  &__wrapper--other-once {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
}

.order-info {
  .form-inner {
    padding: 0;
    border: 1px solid $gray;
    border-bottom: none;
    font-size: calc(1px + 3.5vmin);
    dl {
      width: 100%;
      border-bottom: 1px solid $gray;
      margin: 0;
      display: table;
      dt {
        width: 32%;
        min-width: 32%;
        border: 0;
        padding: 0.5vmax 1.3vw;
        display: table-cell;
        vertical-align: middle;
      }
      dd {
        width: 67%;
        border: 0;
        padding: 0.5vmax 1.3vw;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .button-wrapper {
    text-align: center;
    a {
      @include button-centering;
      width: 100%;
      margin: 3.2vmax 0 0;
    }
    a:first-child {
      margin-bottom: 0;
    }
  }
}