/* ログイン・会員登録(SP) */
.login {
  h3 {
    font-size: calc(18px + 2vmin);
  }
  .login-form-normal {
    background-color: $white;
    margin-bottom: 6vmax;
    .input-wrapper {
      width: 100%;
      &__input--normal {
        width: 100%;
        max-height: 60px;
        height: $inputHeight;
        border-radius: $inputBorderRadius;
        font-size: $font28;
        text-indent: 0.5rem;
        margin: 0 0 2.5vmax 0;
      }
    }
    .button-wrapper {
      width: 100%;
      .button-red {
        margin-bottom: 3vmax;
      }
      span {
        padding: 0;
      }
    }
    .password-reissue{
      font-size: $font28;
    }
  }
  .login-form-sns {
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 5vmax;
    h3 {
      font-size: calc(18px + 2vmin);
    }
    .login-normal {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      border-right: none;
      .input-wrapper {
        width: 100%;
        padding: 0;
        &__input--normal {
        max-width: 100%;
        width: 100%;
        max-height: 60px;
        height: $inputHeight;
        border-radius: $inputBorderRadius;
        font-size: $font28;
        text-indent: 0.5rem;
        margin: 0 0 2.5vmax 0;
        }
      }
      .button-wrapper {
        width: 100%;
        a {
          margin: 0 0 4vmax;
        }
      }
    }
    .login-sns {
      width: auto;
      min-width: 100%;
      display: inline-block;
      margin: 3vmax -2.6vw 0;
      padding: 3vmax 2.6vw 1vmax;
      background-color: $lightGray;
      border-left: none;
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
      &__button-sns-wrapper {
        width: 100%;
        padding: 0;
        a {
          margin-bottom: 3.5vmax;
          &:last-child {
            margin-bottom: 2vmax;
            margin-top: 0;
          }
        }
      }
    }
  }
  .section-title {
    @include section-title-center;
  }
  h3 {
    display: none;
  }
  .password-reissue{
    font-size: $font28;
  }
}

.entry {
  .section-title {
    @include section-title-center;
  }
}

.entry-confirm {
  p {
    font-size: $font28;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    a {
      max-width: 140px;
      width: calc(5px + 20vmin);
      margin-top: 4vmax;
    }
    &--centering {
      margin: 0;
      a {
        max-width: 100%;
        width: 100%;
        margin-top: 0;
      }
    }
    .button-back {
      margin-right: 0;
    }
  }
}

.entry-pre-complete, .entry-complete {
  p {
    margin: 0;
    font-size: $font28;
    line-height: 130%;
  }
  .button-wrapper {
    margin-top: 7vmax;
  }
}

.agree-wrapper {
  padding-bottom: 6vw;

  &__checkbox {
    width: 100%;
    margin: 0 0 3vmax;
    text-align: center;
    padding: 12px 0;
    box-shadow: 0 calc(0.5vmin) calc(1vmin) calc(0.5vmin) $gray;
    border-radius: 4px;
    &:before {
      // top: calc(2px + 44%);
      top: 24px;
      left: 8.5%;
    }
    &:after {
      // top: calc(5px + 43%);
      top: 25px;
      left: 6%;
    }
    span {
      font-size: 4vw;
      padding-left: 3.5vw;
    }
  }
  &__text {
    font-size: $font28;
  }
}