.faq {
  p {
    margin: 0;
  }
  h4 {
    margin-bottom: 0;
  }
  &__toc {
    width: 100%;
    li {
      display: block;
      font-size: 18px;
      border: 1px solid $gray;
      border-bottom: none;
      padding: 15px;
      font-weight: bolder;
      &:last-child {
        border-bottom: 1px solid $gray;
      }
      a {
         display: block;
         &:hover {
           text-decoration: none;
           color: $black;
           opacity: 0.9;
         }
      }
    }
  }
  &__contents {
    margin: 45px 0 0;
  }
  &__contents-inner {
    padding: 20px;
    margin-bottom: 10px;
  }
  &__headline {
    font-size: 20px;
    border-bottom: 1px solid $gray;
    line-height: 100%;
    padding: 10px 0;
    font-weight: normal;
  }
  &__item-list {
    margin-bottom: 10px;
    &--question {
      font-size: 18px;
      display: flex;
      align-items: baseline;
      font-weight: bold;
      white-space: pre-wrap;
    }
    &--answer {
      font-size: 16px;
      color: $colorfaqAnswer;
      display: flex;
      align-items: baseline;
      white-space: pre-wrap;
      a {
        color: $lightBlue;
      }
    }
  }
  .Q {
    font-weight: bold;
    font-size: 22px;
    margin-right: 10px;
  }
  .A {
    font-weight: bold;
    font-size: 22px;
    margin-right: 10px;
    color: $red;
  }
}