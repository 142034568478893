/* 共通(PC) */
$black: #626262;
$gray: #ccc;
$lightGray: #F0F0F0;
$lightgray1: #e3e3e3;
$white: white;
$red: #EA352D;
$thinRed: #FFEDF5;
$blue: blue;
$lightBlue: #00B2F4;
$colorTwitter: #55ACEE;
$colorFacebook: #3B5998;
$colorLINE: #1DCD00;
$colorDisabled: #888888;
$colorfaqAnswer: #888888;
$colorfontGray: #888888;

$labelBgColorNew: #ffde00;
$labelFontColorNew: #e5004f;
$labelBgColorSale: #ea352d;
$labelBgColorComingSoon: #11b79f;
$labelBgColorClosingSoon: #f9802a;
$labelBgColorSoldOut: #626262;
$labelBgColorPrice: #e5004f;

* {
//   font-family: 'メイリオ', Meiryo, sans-serif;
  color: $black;
}

ul {
  list-style: none;
  padding: 0;
}

dl, dt, dd {
  margin: 0;
}

a {
  text-decoration: none;
  color: $black;
}

a:hover{
  opacity: 0.9;
}
button:hover {
  opacity: 0.99;
}

p {
  font-size: 14px;
}

input,
textarea {
  margin: 0;
  border: 1px solid $gray;
  border-radius: 2.5px;
  font-size: 14px;
  text-indent: 5px;
}

input::-ms-reveal {
  visibility: hidden;
}

input::-webkit-input-placeholder{
  color: $gray;
}
input:-moz-placeholder{
  color: $gray;
}
input::-moz-placeholder{
  color: $gray;
}
input:-ms-input-placeholder{
  color: $gray;
}
input::placeholder {
  color: $gray;
}

input:focus, button:focus, select:focus {
  outline: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance:textfield;
}

@mixin container-centering {
  width: 940px;
  margin: 0 auto;
}

@mixin container-bg-color {
  background-color: $lightGray;
}

@mixin normal-button-base {
  width: 340px;
  height: 50px;
  border-radius: 5px;
  border-style: none;
  font-size: 20px;
  box-shadow: 0 3px 5px 2px $gray;
  text-align: center;
  cursor: pointer;
}

@mixin mini-button-base {
  width: 100px;
  height: 30px;
  border: 1px solid $gray;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 $gray;
  text-align: center;
  padding-top: 0.2rem;
  background: $white;
  display: inline-block;
  cursor: pointer;
  font-size: 13.5px;
}

@mixin mini-button-round-base {
  width: 80px;
  height: 30px;
  border: 1px solid $gray;
  border-radius: 5px;
  box-shadow: 0 1px 2px 1px $gray;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  background: $white;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}

@mixin button-color($bgColor, $fontColor) {
  background-color: $bgColor;
  color: $fontColor;
}

@mixin button-hover {
  color: $white;
  text-decoration: none;
}

@mixin button-centering {
  display: block;
  margin: 0 auto;
}

@mixin form-base {
  border: 1px solid $gray;
  margin: 0 auto;
  font-size: 14px;
}

@mixin table-color($thColor: $lightGray, $borderColor: $gray) {
  border-color: $borderColor;
  th {
    background-color: $thColor;
  }
}

@mixin select-base {
  border-radius: 2px;
}

@mixin label-base {
  display: inline-block;
  min-width: 90px;
  text-align: center;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;

  vertical-align: middle;
}

@mixin label-color($bgColor, $fontColor: $white) {
  background-color: $bgColor;
  color: $fontColor;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pc-logo {
  width: 130px;
  height: auto;
  min-height: 100%;
}

.d-block { display: block !important; }
.d-flex { display: flex; }
.d-flex-center { justify-content: center; }
.d-flex-a-center { align-items: center; }

.t-center { text-align: center; }

.fs14 { font-size: 14px; }
.fs12 { font-size: 12px; }

.fw-bold  { font-weight: bold; }

.select-wrapper {
  // height: 30px;
  position: relative;
  display: inline-block;

  &::after {
    position: relative;
    top: -0.05rem;
    right: 1.2rem;
    width: 0;
    height: 0;
    padding: 0;
    content: '▼';
    font-size: 0.5rem;
    pointer-events: none;
  }
}

.select-pref-wrapper__select {
  width: 220px;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  outline: none;
  background: none;
  background-image: none;
  box-shadow: 0 1px 0 0 $gray;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 8px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 2px;

  &::-ms-expand {
    display: none;
  }
}

.select-pref-wrapper__select--pref {
  width: 100px;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  outline: none;
  background: none;
  background-image: none;
  box-shadow: 0 1px 0 0 $gray;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 8px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 2px;

  &::-ms-expand {
    display: none;
  }
}

.select-number-bg {
  // height: 30px;
  position: relative;
  display: inline-block;
  &__select--number {
    width: 80px;
    height: 30px;
    padding-right: 1em;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    outline: none;
    background: none;
    background-image: none;
    box-shadow: 0 1px 0 0 $gray;
    -webkit-appearance: none;
    appearance: none;
    padding: 5px 8px;
    color: $black;
    border: 1px solid $gray;
    border-radius: 2px;

    &::-ms-expand {
      display: none;
    }
  }
  &::after {
    position: absolute;
    top: 25%;
    right: 20%;
    width: 0;
    height: 0;
    padding: 0;
    content: '▼';
    font-size: 0.5rem;
    pointer-events: none;
    -webkit-transform: translateY(25%);
    -ms-transform: translateY(25%);
    transform: translateY(25%);
  }
}

.select-wrapper__select--hint1 {
  position: relative;
  width: 200px;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  outline: none;
  background: none;
  background-image: none;
  box-shadow: 0 1px 0 0 $gray;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 8px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 2px;

  &::-ms-expand {
    display: none;
  }
}

.select-wrapper__select--contact {
  position: relative;
  width: 260px;
  padding-right: 1em;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  outline: none;
  background: none;
  background-image: none;
  box-shadow: 0 1px 0 0 $gray;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 8px;
  color: $black;
  border: 1px solid $gray;
  border-radius: 2px;

  &::-ms-expand {
    display: none;
  }
}


.sp {
  display: none;
}

.attention {
  color: $red;
  font-size: 0.7rem;
  margin: 0;
}

.text {
  margin: 0;
  font-size: 12px;
}

.bg-error {
  background-color: #FFEDF5;
  border: 1px solid #FFB0BD;
}

.text-error {
  color: $red;
  font-size: 12px;
  margin: 5px 0 0 0;
}

.button-back {
  width: 120px;
  height: 50px;
  background: $white;
  border: 1px solid $gray;
  border-radius: 6px;
  font-size: 20px;
  box-shadow: 0 3px 3px 0 $gray;
  text-align: center;
  cursor: pointer;
  position: relative;
  &:hover {
    color: $black;
    text-decoration: none;
  }
  &__icon {
    position: absolute;
    left: 10px;
    top: 12px;
  }
  span {
    margin-left: 10px;
  }
}

button:disabled {
  @include button-color($colorDisabled, $white);
  &:hover {
    opacity: 1;
  }
}

.button-red {
  @include normal-button-base;
  @include button-color($red, $white);
  &:hover {
    @include button-hover;
  }
}

.button-white {
  @include normal-button-base;
  @include button-color($white, $black);
  border: 1px solid $gray;
  box-shadow: 0 3px 5px 0px $gray;
  &:hover {
    @include button-hover;
    color: $black;
  }
}

.button-white-red {
  @include normal-button-base;
  @include button-color($white, $black);
  border: 1px solid $gray;
  box-shadow: 0 3px 5px 0px $gray;

  position: relative;
  margin: auto;
  transition: color 0.3s 0.1s ease-out;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content:'';
    border-radius: 50%;
    display: block;
    width: 1px;
    height: 1px;
    text-align: center;
    transition: box-shadow 0.3s ease-out;
    z-index: -1;
  }
  &:hover {
    // background: $red;
    color: $white;
    // opacity: 1;
    // border-style: none;
    &::after{
      // width: 100%;
      background-color:$red;
      box-shadow: 0 0 10px 170px rgba($red,1);
    }
  }
}

.button-white-long {
  @extend .button-white;
  width: 450px;
}

.button-white-middle {
  @extend .button-white;
  font-size: 14px;
  width: 320px;
  height: 44px;
}

.button-submit {
  @include normal-button-base;
  @include button-color($red, $white);
}

.button-disabled {
  @include normal-button-base;
  @include button-color($colorDisabled, $white);
}

.button--tw {
  @include normal-button-base;
  @include button-color($colorTwitter, $white);
  &:hover {
    @include button-hover;
  }
}

.button--fb {
  @include normal-button-base;
  @include button-color($colorFacebook, $white);
  &:hover {
    @include button-hover;
  }
}

.button-line {
  @include normal-button-base;
  @include button-color($colorLINE, $white);
  &:hover {
    @include button-hover;
  }
}

.button-mini {
  @include mini-button-base;
}

.button-mini-round {
  @include mini-button-round-base;
}

.label-new {
  @include label-base;
  @include label-color($labelBgColorNew, $labelFontColorNew);
}

.label-sale {
  @include label-base;
  @include label-color($labelBgColorSale);
}

.label-coming-soon {
  @include label-base;
  @include label-color($labelBgColorComingSoon);
}

.label-closing-soon {
  @include label-base;
  @include label-color($labelBgColorClosingSoon);
}

.label-sold-out {
  @include label-base;
  // @include label-color($labelBgColorSoldOut);

  border: 1px solid $labelBgColorSoldOut;
  //background-color: $bgColor;
  color: $labelBgColorSoldOut;
}

.label-sales-period {
  // @include label-base;
  // padding: 2px 10px;
  // border: 1px solid $gray;

  display: inline-block;
  padding: 0px 15px 0px 0px;
  margin-right: 15px;
  border-right: 1px solid $gray;
  white-space: nowrap;
}

.ol-list-parentheses {
  list-style-type: decimal;
  .no-marginbtm {
    list-style-type: lower-alpha;
  }
}

.required {
  color: $red;
  padding-left: 5px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
}

.container {
  max-width: 988px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  flex-grow: 1;
}

.header {
  width:100%;
  border-bottom: 1px solid $gray;
  margin-bottom: 35px;
  .header-inner {
    max-width: 1048px;
    width: 100%;
    height: 54px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;

    .pc {
      display: block;
    }
    .sp {
      display: none;
    }
    .header-logo {
      margin-left: 10px;
      align-self: center;

      h1 {
        margin-bottom: 0px;
      }
    }
    .header-nav {
      display: inline-block;
      align-self: center;
      font-size: 14px;
      .header-nav--is-login {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .header-nav__mypage {
          margin-right: 50px;
          display: flex;
          position: relative;
          span {
            align-self: center;
            font-weight: bold;
          }
          img {
            margin-right: 10px;
          }
          .header-nav__icon--active {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
          &:hover {
            text-decoration: none;
            span {
              color: $red;
            }
            .header-nav__icon--active {
              opacity: 1;
            }
          }
        }
        .header-nav__logout {
          border: none;
          text-decoration: none;
          background-color: transparent;

          .header-nav__button--logout {
            border: 1px solid $black;
            padding: 5px 10px;
            border-radius: 3px;
            font-weight: bold;
            line-height: 2.1em;
            &:hover {
              color: $white;
              border: 1px solid $red;
              background-color: $red;
            }
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      .header-nav--is-logout {
        display: flex;
        justify-content: flex-end;
        .header-nav__entry {
          display: flex;
          position: relative;
          margin-right: 50px;
          span {
            align-self: center;
            font-weight: bold;
          }
          img {
            margin-right: 10px;
            height: 22px;
          }
          .header-nav__icon--active {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
          &:hover {
            text-decoration: none;
            .header-nav__icon--active {
              opacity: 1;
            }
            span {
              color: $red;
            }
          }
        }
        .header-nav__login {
          display: flex;
          position: relative;
          span {
            align-self: center;
            font-weight: bold;
          }
          img {
            height: 22px;
            margin-right: 10px;
          }
          .header-nav__icon--active {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
          &:hover {
            text-decoration: none;
            .header-nav__icon--active {
              opacity: 1;
            }
            span {
              color: $red;
            }
          }
        }
      }
    }
  }
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 24px;
  border-bottom: 1px solid $gray;
  margin-bottom: 15px;
  padding: 0 10px 5px;
  line-height: 100%;
}

.form-inner {
  @include form-base;
  margin-bottom: 30px;
  dl {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $gray;
    &:last-child {
      border-bottom: none;
    }
    dt {
      width: 27%;
      background-color: $lightGray;
      padding: 10px 10px;
      box-sizing: border-box;
      font-weight: normal;
    }
    dd {
      width: 73%;
      padding: 10px 2.3%;
      box-sizing: border-box;
      align-self: center;
      word-break: break-all;
      input {
        align-self: center;
      }
      .attention {
        margin: 5px 0;
        &:last-child {
          margin: 5px 0 0 0;
        }
      }
      .text {
        padding: 5px 0 0 0;
      }
    }
  }
  &__input--name {
    max-width: 250px;
    width: 40%;
    &:first-child {
      margin: 0 3% 0 0;
    }
  }
  &__input--short {
    width: 60px;
  }
  &__input--tel {
    width: 60px;
  }
  &__input--postalcode {
    width: 60px;
  }
  &__input--middle {
    width: 300px;
    width: 50%;
  }
  &__input--long {
    max-width: 500px;
    width: 80%;
  }

  &__postalcode {
    .form-inner__postalcode-wrapper {
      display: inline-flex;
      align-items: flex-start;
      input {
        align-self: flex-start;
      }
      label {
        padding-top: 3px;
      }
      span {
        margin: 0 5px;
        padding-top: 2px;
      }
    }
    .form-inner__button-wrapper {
      display: inline-block;
      padding: 0 0 0 20px;
    }
  }
  &__address {
    &-wrapper{
      input{
          margin-top: 10px;
      }
    }
  }
  .form-inner__address-wrapper {
    p {
      margin: 0;
    }
    .text {
      padding-bottom: 0px;
    }
  }
  &__tel {
    span {
      margin: 0 5px;
    }
  }
  &__birthday {
    .text {
      margin: 0 15px 0 5px;
      font-size: 16px;
    }
  }
  &__announce {
    margin-left: -15px;
    label {
      display:inline;
    }
    .annouce-bottom-label {
      margin-left: 30px;
      margin-bottom: 0;
    }
  }
  .form-inner__birthday-wrapper {
    display: flex;
  }
  &__mail {
    :not(:first-child).form-inner__input--long {
      margin: 10px 0 0 0;
    }
  }
  &__password {
    :not(:first-child).form-inner__input--long {
      margin: 10px 0 0 0;
    }
  }
  &__hint {
    input {
      margin-top: 10px;
    }
  }
  &__button-wrapper {
    .text {
      padding-bottom: 0;
    }
  }
  &__button--mini {
    @include mini-button-base;
    &:hover {
      color: $black;
      text-decoration: none;
    }
  }
}

.form-inner__textarea {
  height: 140px;
}

.page-top {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0  22px 22px 0;
  transform: translate3d(0, 0, 0);
  z-index: 1;
}

.footer {
  @include container-bg-color;
  width: 100%;
  margin-top: 60px;
  padding: 40px 0 20px;
  .footer-inner {
    max-width: 1048px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0;
    .footer-nav {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 0 auto;
      max-width: 1000px;
      &__item {
        font-size: 12px;
        padding: 0 calc(4px + 1.1vw);
        a:hover {
          color: $black;
          text-decoration-color: $black;
        }
      }
      &__item + li {
        border-left: 1px solid $gray;
      }
      li:first-child {
        padding-left: 0;
      }
      li:last-child {
        padding-right: 0;
      }
    }
    .footer-logo {
      text-align: center;
      margin-top: 60px;
      img {
        width: 80px;
        height: auto;
      }
    }
    .copyright {
      text-align: center;
      font-size: 10px;
      margin: 20px 0 0 0;
    }
  }
}

// アコーディオン
.accordion {
  width: 100%;
	margin: 0 auto;
  padding: 0;
  font-size: 12px;
  label {
    display: block;
    position: relative;
    margin: 0 0 2px 0;
    padding: 10px 5px;
    color: $black;
    background: $lightGray;
    border-bottom: 1px solid $gray;
    // cursor: pointer;
    // &::before {
    //   position: absolute;
    //   content: '';
    //   right: 1em;
    //   top: 30%;
    //   width: 15px;
    //   height: 15px;
    //   border: 0px;
    //   border-top: solid 2px $black;
    //   border-right: solid 2px $black;
    //   -webkit-transition-duration: 0.5s;
    //   -ms-transition-duration: 0.5s;
    //   transition-duration: 0.5s;
    //   -ms-transform: rotate(135deg);
    //   -webkit-transform: rotate(135deg);
    //   transform: rotate(135deg);
    //   margin-top: -4px;
    // }
  }
  input {
    display: none;
  }
  &__list {
    padding: 0;
    margin: 0;
    // transition: all 0.6s;
    overflow-y: hidden;
    // max-height: 0;
    // max-height: 300px;
    // opacity: 1;
    li {
      line-height: 200%;
      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 15px;
        border-bottom: 1px solid $gray;
      }
    }
  }
}

// 削除予定
// .accordion input[type=checkbox]:checked ~ .accordion__list {
// 	max-height: 300px;
// 	opacity: 1;
// }

// .accordion input[type=checkbox]:checked ~ label::before {
//     -webkit-transition-duration: 0.5s;
//     -ms-transition-duration: 0.5s;
//     transition-duration: 0.5s;
//     -ms-transform: rotate(315deg);
//     -webkit-transform: rotate(315deg);
//     transform: rotate(315deg);
//     top: 50%;
// }

// モーダル
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  outline: transparent;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
  z-index: 99;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.close-modal {
  display: inline-block;
  position: absolute;
  top: -40px;
  right: 0%;
  outline: transparent;
  background: none;
  border: none;
  img {
    width: 30px;
    height: auto;
  }
}

// checkbox
input[type=checkbox] {
  display: none;
}
.checkbox {
  box-sizing: border-box;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  position: relative;
  display: inline-block;
  padding: 0 12px 0 42px;
  vertical-align: middle;
  cursor: pointer;
  &:after {
    -webkit-transition: border-color 0.5s linear;
    transition: border-color 0.5s linear;
    position: absolute;
    top: 59%;
    left: 15px;
    display: block;
    margin-top: -10px;
    width: 16px;
    height: 16px;
    border: 1px solid $gray;
    border-radius: 3px;
    content: '';
    background-color: $lightGray;
    input[type=checkbox]:checked + & {
      border: 1px solid $lightBlue;
      background-color: $lightBlue;
    }
  }
  &:before {
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    position: absolute;
    top: 59%;
    left: 21px;
    display: block;
    margin-top: -7px;
    width: 5px;
    height: 9px;
    border-right: 1px solid $white;
    border-bottom: 1px solid $white;
    content: '';
    opacity: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
    input[type=checkbox]:checked + & {
      opacity: 1;
    }
  }
}

// twitterシェアボタン
.SocialMediaShareButton--twitter {
  width: 112px;
  height: 30px;
  background: $lightBlue;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  outline: none;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  span {
    color: $white;
    font-size: 14px;
  }
  &:hover {
    opacity: 0.9;
  }
}

.flash-message {
  width: 100%;
  height: 54px;
  background: $black;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 99;
  animation: transition 3s linear 0s 1;
  animation-fill-mode: forwards;
  -webkit-animation: transition 3s linear 0s 1;
  -webkit-animation-fill-mode: forwards;

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: white;
      font-size: 20px;
    }
  }
}

@keyframes transition {
  0%  {opacity:0;height:0;}
  10% {opacity:0.8;height:54px;}
  90% {opacity:0.8;height:54px;}
  100% {opacity:0;height:0;}
}
@-webkit-keyframes transition {
  0%  {opacity:0;height:0;}
  10% {opacity:0.8;height:54px;}
  90% {opacity:0.8;height:54px;}
  100% {opacity:0;height:0;}
}

.text-center {
  text-align: center;
}

.link-color { color:#00B2F4; }

.mh2 { margin: 2px 0 }

.mb15 { margin-bottom: 15px }
.mb25 { margin-bottom: 25px }
.mb30 { margin-bottom: 30px }

.testAnim {
  margin: 0 auto;
  background-color: #ccc;
  width: 100%;
  height: 600px;
  max-width: 530px;
  max-height: 600px;
}

.dNone { display: none !important; }

// canvasアニメーション関連
// animation_container
.canvas-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 598px;
  margin: 0 auto;
}
.canvas-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 530px;
  min-width: 265px;
  max-height: 600px;
  min-height: 300px;
  // background-color: #cccccc;
  // border: 1px solid #ccc;
  .canvas {
    position: absolute;
    width: 100%;
    left: 0;
    z-index:10;
  }

  .canvas-overlay {
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index:20;
  }
}

.share-button {
  font-size: 14px;
  color: white;
  // width: 112px;
  height: 26px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  outline: none;
  cursor: pointer;
  float: right;
  &--size_s {
    width: 112px;
  }
  &--twitter {
    border: none;
    background: #55ACEE;

    img {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
    span {
      color: white;
      // font-size: 14px;
    }
  }
}

.select-item-wrap {
  > div {
    padding-left: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
  .select-item {
    padding-top: 5px;
  }
}

.MuiTypography-body1 {
  font-family: '\30E1\30A4\30EA\30AA', Meiryo, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
