/* ログイン・会員登録(PC) */
.login {
  .login-form-normal {
    background-color: $lightGray;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 0;
      padding: 45px 0;
    }
    .input-wrapper {
      width: 600px;
      margin: 0 auto;
      display: inline-flex;
      flex-direction: column;
      &__input--normal {
        width: 600px;
        height: 40px;
        margin: 0 0 30px 0;
        font-size: 16px;
        text-indent: 1em;
      }
    }
    .password-reissue{
      color: $lightBlue;
      text-align: right;
      &:hover {
        text-decoration: underline;
      }
    }
    .button-wrapper {
      display: block;
      width: 340px;
      margin: 0 auto;
      .button-red {
        margin: 0 auto 30px;
      }
      span {
        display: block;
        text-align: right;
        font-size: 14px;
        padding-bottom: 40px;
      }
      .recaptcha {
        margin: 0 auto;
        width: 300px;
      }
    }
  }
  .login-form-sns {
    background-color: $lightGray;
    margin-bottom: 60px;
    display: flex;
    padding: 35px 0;
    h3 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 0;
      padding: 0 0 40px;
    }
    .button-wrapper {
      display: inline-block;
      margin: 0 auto;
      a {
        display: block;
        margin: 0 0 30px;
      }
      span {
        display: block;
        text-align: right;
        font-size: 14px;
      }
    }
    .login-normal {
      // width: 540px;
      min-width: 340px;
      width: 56.3829787%;
      border-right: 1px solid $gray;
      display: flex;
      flex-direction: column;
      .input-wrapper {
        // margin: 0 auto;
        padding: 0 5.66037736%;
        &__input--normal {
          max-width: 470px;
          // min-width: 400px;
          width: 100%;
          height: 40px;
          margin-top: 0;
          margin-bottom: 30px;
          font-size: 16px;
          text-indent: 1em;
        }
      }
      .password-reissue{
        color: $lightBlue;
        text-align: right;
        margin: 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .login-sns {
      min-width: 350px;
      width: calc(100% - 56.3829787%);
      text-align: center;
      padding: 0 auto;
      &__button-sns-wrapper {
        display: inline-flex;
        flex-direction: column;
        a {
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.entry {
  .button-submit {
    @include button-centering;
  }
}

.entry-form {
  .button-wrapper {
    display: inline-block;
    @include button-centering;
  }
}

.entry-confirm {
  .button-wrapper {
    display: flex;
    a {
      display: inline-block;
    }
    &--centering {
      width: 100%;
      margin-left: -120px;
      display: flex;
      a,
      .center {
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}

.entry-pre-complete, .entry-complete {
  p{
    margin: 40px 0 0 0;
    text-align: center;
  }
  .button-wrapper {
    margin-top: 80px;
    text-align: center;
  }
}

.agree-wrapper {
  padding-bottom: 15px;
  text-align: center;
  a {
    color: $lightBlue;
  }
  &__checkbox {
    margin: 0 0 20px;
    padding: 10px 12px 10px 42px;
    box-shadow: 0 2px 3px 1px $gray;
    border: 1px solid $gray;
    border-radius: 4px;
    &:before {
      top: 21px;
    }
    &:after {
      top: 22px;
      left: 15px;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}