/* 共通(SP) */
$headerHight: calc(14vmin + 1.5vw);
$spMenuLogo: "../images/sp/sp_menu_bg_logo.png";
$inputHeight: 10vmin;
$inputBorderRadius: calc(2px + 0.5vw);
$inputFontSize: calc(10px + 1.5vw);

$font56: calc(9.6px + 6.0vmin);
$font40: calc(8px + 4.2vmin);
$font36: calc(4px + 4.2vmin);
$font34: calc(2px + 4.2vmin);
$font32: calc(3.2px + 3.6vmin);
$font30: calc(4px + 3.5vmin);
$font28: calc(2px + 3.5vmin);
$font26: calc(1px + 3.4vmin);
$font24: calc(2px + 3vw);

p {
  font-size: calc(14px + 0.25vw);
  line-height: 120%;
}
dt {
  width: 100%;
}
input {
  max-width: 100%;
  max-height: 60px;
  height: $inputHeight;
  border-radius: $inputBorderRadius;
  font-size: $font28;
  padding-left: calc(0.25vw);
  -webkit-appearance: none;
}
textarea {
  max-width: 100%;
  border-radius: $inputBorderRadius;
  font-size: $font28;
  padding-left: calc(0.25vw);
}
option {
  width: 120%;
}
a:focus, a:hover {
  text-decoration: none;
}

.mb30 { margin-bottom: 8.6vw; }

@mixin normal-button-base {
  width: 100%;
  max-height: 96px;
  height: calc(2px + 13vmin);
  font-size: $font36;
  border-radius: $inputBorderRadius;
  box-shadow: 0 calc(0.5vmin) calc(1vmin) calc(0.2vmin) $gray;
}

@mixin mini-button-base {
  width: 100%;
  max-height: 60px;
  height: $inputHeight;
  border-radius: $inputBorderRadius;
  font-size: $font28;
  padding: 0 2vw;
  background: $white;
  display: block;
  box-shadow: 0 2px 3px 1px $gray;
}

@mixin label-base {
  min-width: 22vw;
  padding: 0;
  font-size: 2.6vw;
}

@mixin section-title-center {
  text-align: center;
  border-style: none;
  margin: 0;
  span {
    padding: 0;
  }
}

.button-back {
  min-width: 80px;
  max-width: 140px;
  width: calc(5px + 20vmin);
  max-height: 84px;
  height: calc(12vmin);
  font-size: $font28;
  border-radius: calc(1vmin);
  background: $white;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 calc(0.3vmin) calc(1vmin) calc(0.2vmin) $gray;
  &__icon {
    position: static;
    max-width: 30px;
    width: calc(1px + 2.5vmin);
    height: auto;
    margin: 0 2vmin;
  }
}

.button-mini-round {
  // max-height: 72px;
  height: calc(1px + 8.1vmin);
  font-size: $font28;
  border-radius: calc(1px + 1vmin);
  padding: 0 2.6vmin;
}

.button-red, .button-white, .button-white-red, .button-submit, .button-disabled, .button--tw, .button--fb, .button-line {
  @include normal-button-base;
}

.button-white-long {
  @include normal-button-base;
  font-size: calc(0.15rem + 3.6vmin);
}

.button-white-middle {
  @include normal-button-base;
  font-size: calc(0.15rem + 3.6vmin);
}

.label-new, .label-sale, .label-closing-soon, .label-sold-out {
  @include label-base;
}

.label-coming-soon {
  @include label-base;
  padding: 0 1.5vmin;
}
.label-sales-period {
  @include label-base;
  border: none;
}

.ol-list-parentheses {
  list-style-type: decimal;
  .no-marginbtm {
    list-style-type: lower-alpha;
  }
}

.pc {
  display: none;
}
.sp {
  display: block;
}

.fs14 { font-size: calc(1px + 3vmin); }

.section-title {
  font-size: $font34;
  margin: 0 -2.6vw 1.5vmax;
  padding-top: 1vmax;
  padding-bottom: 1vmax;
  span {
    padding-left: 0;
  }
}

.wrapper {
  max-width: 100%;
  overflow: hidden;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: $headerHight 2.6vw 0;
}
.header {
  width:100%;
  min-width: auto;
  height: 14vmin;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 20;
  .header-inner {
    width: 100%;
    height: 14vmin;
    padding: 0 2.6vw;
    display: inline-flex;
    align-items: center;
    &__sp-menu {
      display: block;
      z-index: 3;
      label {
        margin: 0;
      }
    }
    h1 {
      margin: 0;
      font-size: 0;
      height: 100%;
      a {
        display: inline-block;
        height: 100%;
      }
    }
    .header-logo {
      display: block;
      height: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      img {
        max-width: 200px;
        width: calc(15px + 27.5vmin);
        height: 100%;
      }
    }
    .pc {
      display: none;
    }
    .sp {
      display: block;
      text-align: center;
    }
  }
}

.form-inner {
  width: 100%;
  border-style: none;
  font-size: $font28;
  margin-bottom: 4vmax;
  dl {
    margin: 0 -2.6vw;
    border-bottom: none;
    dt {
      width: 100%;
      padding: calc(1vw) 0 calc(1vw) 2.6vw;
      border-bottom: 1px solid $gray;
    }
    dt:first-child {
      border-top: 1px solid $gray;
    }
    dd {
      width: 100%;
      padding: 2.6vw;
      display: flex;
      flex-wrap: wrap;
      .text {
        padding: 0.3vmin 1.2vw 0 0;
      }
    }
  }
  dl:last-child {
    border-bottom: 1px solid $gray;
  }
  label.postal-code-label, p {
    display: none;
  }
  .text-error {
    display: block;
    width: 100%;
    font-size: $font26;
    margin-top: 10px;
  }
  &__input--tel {
    width: 30%;
  }
  &__input--postalcode {
    width: 28%;
  }
  &__input--long, &__input--middle {
    max-width: 100%;
    width: 100%;
  }
  &__input--name {
    max-width: 100%;
    width: 48.3%;
    &:first-child {
      margin: 0;
    }
  }
  &__name {
    display: flex;
    justify-content: space-between;
  }
  &__address {
    &-wrapper{
      width: 100%;
      p {
        padding-top: 10px;
      }
      input{
        margin-top: 2.6vw;
      }
    }
  }
  &__tel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    span {
      margin: 0 1vw;
    }
  }
  &__postalcode {
    display: flex;
    justify-content: space-between;
    .form-inner__postalcode-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;
      span {
        padding-top: calc(1vh);
        margin: 0 1vw;
      }
      :nth-child(4){
        margin-right: calc(2.3vw);
      }
    }
    .form-inner__button-wrapper {
      display: inline-block;
      padding: 0;
      .form-inner__button--mini {
        width: 100%;
      }
    }
  }
  &__birthday {
    .text {
      margin: calc(1vh) 5px 0 calc(-3vw);
    }
    &-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__mail {
    :not(:first-child).form-inner__input--long {
      margin: 2.6vw 0 0 0;
    }
  }
  &__password {
    :not(:first-child).form-inner__input--long {
      margin: 2.6vw 0 0 0;
    }
  }
  &__hint {
    p {
      margin-top: 20px;
    }
  }
  &__hint2 {
    width: 100%;
    input {
      margin-top: 2.6vw;
    }
  }
  &__announce {
    width: 100%;
    margin-left: 5px;
    label, p {
      width: 100%;
      display: block;
    }
    .annouce-bottom-label {
      margin: 0 0 0 7%;
      font-size: calc(2px + 3.5vmin);
    }
  }
  &__button--mini {
    @include mini-button-base;
  }
}

.form-inner__textarea {
  height: 60vw;
}

.select-pref-wrapper {
  width: auto;
  max-height: 60px;
  height: $inputHeight;
  display: inline-block;
  &__select--pref {
    width: 125%;
    max-height: 60px;
    height: $inputHeight;
    padding-right: 1rem;
    cursor: pointer;
    text-overflow: ellipsis;
    background-image: none;
    box-shadow: 0 1px 2px 1px $gray;
    -webkit-appearance: none;
    appearance: none;
    padding: 5px 8px;
    color: $black;
    border-radius: $inputBorderRadius;
    font-size: $font28;
  }
  &::after {
    position: absolute;
    top: 30%;
    right: 0%;
    width: 0;
    height: 0;
    padding: 0;
    font-size: calc(3px + 2vmin);
    pointer-events: none;
  }
}

.select-number-wrapper {
  display: inline-flex;
  width: 33%;
  flex-direction: row;
  .text {
    display: inline;
    font-size: $font28;
  }
}

.select-number-bg {
  width: 100%;
  max-height: 60px;
  height: $inputHeight;
  position: relative;
  &__select--number {
    width: 80%;
    min-width: 0;
    max-height: 60px;
    height: $inputHeight;
    display: inline-block;
    padding-right: 1em;
    cursor: pointer;
    background-image: none;
    box-shadow: 0 1px 2px 1px $gray;
    -webkit-appearance: none;
    appearance: none;
    // padding: 5px 8px;
    color: $black;
    border-radius: $inputBorderRadius;
    font-size: $font28;
    option {
      width: 120px;
    }
  }
  &::after {
    position: absolute;
    top: 30%;
    right: 40%;
    width: 0;
    height: 0;
    padding: 0;
    font-size: calc(3px + 2vmin);
    pointer-events: none;

  }
}

.select-wrapper {
  width: auto;
  max-height: 60px;
  height: $inputHeight;
  position: relative;
  display: inline-block;
  &__select--hint1,
  &__select--contact {
    width: 110%;
    max-height: 60px;
    height: $inputHeight;
    padding-right: 1em;
    box-shadow: 0 1px 2px 1px $gray;
    border-radius: $inputBorderRadius;
    font-size: $font28;
  }
  &::after {
    position: absolute;
    top: 30%;
    right: 0;
    width: 0;
    height: 0;
    padding: 0;
    font-size: calc(3px + 2vmin);
    pointer-events: none;
  }
}

.page-top {
  bottom: 3vmin;
  right: 3vmin;
  margin: 0;
  img {
    max-width: 68px;
    width: calc(30px + 5vmin);
    height: auto;
  }
}

.footer {
  width: 100%;
  min-width: auto;
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 3vmax;
  margin-top: 6vmax;
  .footer-inner {
    padding: 0;
    .footer-nav {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      border-top: 1px solid #ccc;
      padding-top: 4vw;
      &__item {
        width: 49.8%;
        max-height: 90px;
        text-align: center;
        font-size: calc(8px + 1.1vmin);
        padding: 2.6vw;
        a {
          display: block;
        }
      }
      li:last-child {
        padding-right: 2.6vmin;
      }
      &__item + li {
        border-left: none;
      }
    }
    .footer-logo {
      margin-top: 3vmax;
      img {
        width: calc(5px + 13vmin);
      }
    }
    .copyright {
      font-size: calc(1px + 1.7vmin);
      margin-top: calc(1.5vmax);
    }
  }
}


// headerメニュー(SP)

/* ドロワー */
.sp-menu__contents {
  position: fixed;
  top: 0;
  left: 0;
  width: 83%;
  height: 100%;
  background: $lightGray url($spMenuLogo);
  background-repeat: no-repeat;
  background-position: center 95%;
  background-size: 20% auto;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  transition: 0.3s;
  z-index: 4;

  .btn {
    width: 100%;
    padding: calc(2vmax) calc(3vw);
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    background-color: transparent;
    cursor: pointer;

    .btn-icon {
      margin-right: 3vmin;
      max-width: 50px;
      width: calc(10px + 5.2vmin);
      max-height: 50px;
      height: auto;
    }

    .bigger {
      font-size: calc(4px + 4.2vmin);
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 6%;
      padding: 0;
      content: '';
      width: calc(2px + 2vmin);
      height: calc(2px + 2vmin);
      border: 0px;
      border-top: solid 1px $black;
      border-right: solid 1px $black;
      -ms-transform: rotate(45deg) translateY(-50%);
      -webkit-transform: rotate(45deg) translateY(-50%);
      transform: rotate(45deg) translateY(-50%);
      pointer-events: none;
    }
  }
  ul {
    li {
      background: $white;
      border-bottom: 1px solid $gray;
      transition: 0.5ms;
      a {
        width: 100%;
        padding: 3vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .item-wrapper {
          display: flex;
          align-items: center;
          img {
            margin-right: 3vmin;
            max-width: 50px;
            width: calc(10px + 5.2vmin);
            max-height: 50px;
            height: auto;
          }
          span {
            font-size: $font30;
            line-height: 100%;
          }
          .bigger {
            font-size: $font36;
          }
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 6%;
          padding: 0;
          content: '';
          width: calc(2px + 2vmin);
          height: calc(2px + 2vmin);
          border: 0px;
          border-top: solid 1px $black;
          border-right: solid 1px $black;
          -ms-transform: rotate(45deg) translateY(-50%);
          -webkit-transform: rotate(45deg) translateY(-50%);
          transform: rotate(45deg) translateY(-50%);
          pointer-events: none;
        }
      }
      :focus {
        text-decoration: none;
      }
    }
  }
}
/* チェックボックスオンの時 */
.check:checked ~ .sp-menu__contents {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
}

/* チェックボックスを非表示 */
.check {
  display: none;
}

/* メニューボタン(label) */
.sp-menu__button {
  // position: fixed;
  // display: block;
  // top: 0;
  // left: 0;
  img {
    max-width: 50px;
    width: calc(5px + 7vmin);
    height: auto;
  }
  // background: $white;
  // cursor: pointer;
  // z-index: 5;
}

/* コンテンツの閉じる。普段は非表示(label)*/
.close-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  background: $black;
  -webkit-transform:  translate(0, 0);
  transform:  translate(0, 0);
  transition: 0.3s;
  opacity: 0;
  &__button {
    position: fixed;
    max-width: 40px;
    width: calc(10px + 4vmin);
    max-height: 40px;
    height: auto;
    // padding: 3% 5%;
    top: 3vmin;
    right: 3vmin;
    opacity: 1;
    z-index: 5;
  }
}
/* チェックボックスオンの時 */
.check:checked ~ .close-menu {
  visibility: visible;
  opacity: 0.8;
  z-index: 3;
}

.accordion {
  font-size: $font28;
  width: calc(100% + calc(2.6vw + 2.6vw));
  margin: 0 -2.6vw;
  label {
    padding: 1.5vmax 0 1.5vmax 2.6vw;
    // &::before { 削除予定
    //   top: 50%;
    //   margin-top: -1vmin;
    //   -webkit-transform: translate(-50%,-50%) rotate(135deg);
    //   -ms-transform: translate(-50%,-50%) rotate(135deg);
    //   transform: translate(-50%,-50%) rotate(135deg);
    //   width: calc(4vmin);
    //   height: calc(4vmin);
    // }
  }
  &__list {
    padding: 0 4vw;
    li {
      font-size: $font24;
      line-height: 150%;
      &:first-child {
        padding-top: 2vmax;
      }
      &:last-child {
        padding-bottom: 2vmax;
      }
    }
  }
}

// 削除予定
// .accordion input[type=checkbox]:checked ~ .accordion__list {
// 	max-height: 500px;
// 	opacity: 1;
// }

// .accordion input[type=checkbox]:checked ~ label::before {
//   right: 1.5em;
// }

.modal {
  width: 100%;
  padding: 0 2.6vw;
}

.close-modal {
  top: -6vw;
  right: -3%;
  transform: translate(-50%, -50%);
  outline: transparent;
  background: none;
  border: none;
  img {
    max-width: 42px;
    width: calc(10px + 5vmin);
    height: auto;
  }
}

.checkbox {
  padding: 0 12px 0 7%;
  &:before {
    position: absolute;
    // top: calc(6px + calc(1.5vmin / 2));
    top: calc(6px + 1.3vw);
    left: 2.5vw;
    display: block;
    width: calc(2px + 1.2vmin);
    height: calc(6px + 1.5vmin);
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    -webkit-transform: translate(-50%) rotate(45deg);
    -moz-transform: translate(-50%) rotate(45deg);
    -ms-transform: translate(-50%) rotate(45deg);
    -o-transform: translate(-50%) rotate(45deg);
    transform: translate(-50%) rotate(45deg);
    z-index: 1;
  }
  &:after {
    position: absolute;
    top: calc(3px + calc(4vmin / 2));
    left: 0;
    display: block;
    max-width: 34px;
    width: calc(3px + 4vmin);
    max-height: 34px;
    height: calc(3px + 4vmin);
    border: 1px solid $gray;
    border-radius: 3px;
  }
}

// twitterシェアボタン
.SocialMediaShareButton--twitter {
  max-width: 180px;
  width: calc(3px + 23.5vmin);
  max-height: 40px;
  height: calc(1px + 5.1vmin);
  background: $lightBlue;
  border-radius: calc(1px + 0.5vmin);
  text-align: center;
  padding: 0 0 0 1.8vmin;
  img {
    max-width: 24px;
    width: calc(3.5vmin);
    height: auto;
    margin-right: 1vmin;
  }
  span {
    color: $white;
    font-size: $font24;
  }
}

.flash-message {
  height: 14vmin;
  padding-top: 0;
  &__inner {
    span {
      font-size: $font28;
    }
  }
}

@keyframes transition {
  0%  {opacity:0;height:0;}
  10% {opacity:0.8;height:14vmin;}
  90% {opacity:0.8;height:14vmin;}
  100% {opacity:0;height:0;}
}
@-webkit-keyframes transition {
  0%  {opacity:0;height:0;}
  10% {opacity:0.8;height:14vmin;}
  90% {opacity:0.8;height:14vmin;}
  100% {opacity:0;height:0;}
}

// 無限スクロール
.infinite-scroll-component {
  overflow: visible !important;
}

.canvas-wrap {
  height: 106.7vw;
}


.share-button {
  // font-size: 14px;
  // color: white;
  // // width: 112px;
  // height: 26px;
  // border-radius: 4px;
  // text-align: center;
  // display: flex;
  // align-items: center;
  // padding: 0 0 0 10px;
  // outline: none;
  // cursor: pointer;
  // float: right;

    max-height: 40px;
    height: calc(1px + 5.1vmin);
    border-radius: calc(1px + 0.5vmin);
    text-align: center;
    padding: 0 0 0 1.8vmin;
  &--size_s {
    // width: 112px;
    // max-width: 112px;
    width: calc(3px + 23.5vmin);
  }
  &--twitter {
    border: none;
    background: #55ACEE;

    img {
      // width: 18px;
      // height: 18px;
      // margin-right: 4px;
      max-width: 24px;
      width: calc(3.5vmin);
      height: auto;
      margin-right: 1vmin;
    }
    span {
      // font-size: 14px;
      font-size: calc(1px + 3vmin);
    }
  }

}