/* 登録情報編集(PC) */
.user-edit {
  .button-submit {
    @include button-centering;
  }
}

.edit-form {
  .button-wrapper {
    display: inline-block;
    @include button-centering;
  }
}

.user-edit-confirm {
  .button-wrapper {
    display: flex;
    a {
      display: inline-block;
    }
    &--centering {
      width: 100%;
      margin-left: -120px;
      text-align: center;
    }
  }
}

.card-edit, .unsubscribe {
  margin-top: 55px;
  .panel {
    background: $lightGray;
    text-align: center;
    padding: 40px 0;
    p {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}

.unsubscribe {
  margin-bottom: 30px;
}