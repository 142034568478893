.error-inner {
  text-align: center;
  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  h4 {
    font-weight: 100;
    margin-bottom: 20px;
  }
  h6 {
    font-weight: 100;
    margin-bottom: 30px;
  }
  img.error-icon-sp {
    width: 165px;
    height: 121px;
    margin: 0 auto;
  }
}

.maintenance-inner {
  text-align: center;
  h1 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 6vw;
  }
  h5 {
    font-size: 3vw;
    font-weight: 100;
    margin-bottom: 30px;
  }
  img.maintenance-logo-sp {
    width: 30vw;
    margin: 0 auto;
  }
  img.maintenance-icon {
    width: 35vw;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .button--tw {
    max-width: 510px;
    max-height: 86px;
  }
}