/* パスワード再発行(PC) */
.password-card {
  width: 700px;
  background: $lightGray;
  margin: 60px auto;
  padding: 40px 50px;
  text-align: center;
  &__heading {
    font-size: 20px;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    margin-bottom: 30px;
    a {
      color: $lightBlue;
    }
  }
  .summary {
    font-size: 14px;
  }
  .error-message {
    color: $red;
    margin: 30px 0;
    text-align: left;
  }
  &__subheading {
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  &__input--normal {
    width: 600px;
    height: 40px;
    border-radius: 5px;
    font-size: 18px;
  }
  &__input--name {
    width: 290px;
    height: 40px;
    border-radius: 5px;
    font-size: 18px;
  }
  .input-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .button-wrapper {
    display: inline-block;
    margin: 40px auto 0;
  }
  .resetting-conplete-text {
    margin: 0;
  }

  .text-error {
    text-align: left;
    margin-bottom: 0;
  }
}